<template>
  <div class="page-404">
    <h1>Page not found!</h1>
    <p><router-link to="/">返回首页</router-link></p>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    
  },
  data() {
    return {
      
    }
  }
}
</script>

<style lang="scss">
.page-404 {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 20px;
  }
}
</style>
